<!--
 * @Description: 房源列表
 * @Author: 琢磨先生
 * @Date: 2022-05-17 16:21:08
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 09:39:44
-->

<template >
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="关键字">
        <el-input v-model="query.q" placeholder="名称、内部名称" style="width:300px;" clearable></el-input>
      </el-form-item>
      <el-form-item label="房号">
        <el-input v-model="query.house_no" placeholder="房间号" style="width:200px;" clearable></el-input>
      </el-form-item>
      <el-form-item label="城市/区域">
        <el-cascader
          clearable
          :options="area_tree"
          v-model="query.areaIds"
          :props="{
            label: 'name',
            value: 'id',
            expandTrigger: 'hover',
            checkStrictly: true,
          }"
          @change="areaChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="房源类型">
        <el-cascader
          :options="common.buildings"
          v-model="query.buildingIds"
          clearable
          :props="{
            expandTrigger: 'hover',
            label: 'name',
            value: 'id',
            checkStrictly: true,
          }"
          @change="buildingChange"
        ></el-cascader>
      </el-form-item>
      <!-- <el-form-item label="门店">
        <el-select v-model="query.shop_id" placeholder clearable>
          <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in shop_list"></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder clearable style="width:150px;">
          <el-option
            :label="item.text"
            :value="item.value"
            :key="item.value"
            v-for="item in enums.house_status"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-button type="primary" size="small" @click="onEdit()" round icon="plus" v-power="'seller/v1/house/edit/location'">新增</el-button>
      <batch-shop :shops="shop_list" @close="loadData"></batch-shop>
      <batch-set-base-price @close="loadData" :shops="shop_list"></batch-set-base-price>
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border>
      <el-table-column label type="selection" width="50"></el-table-column>
      <el-table-column label="编号" width="80" prop="id"></el-table-column>
      <el-table-column label="LOGO图" width="180">
        <template #default="scope">
          <el-image :src="scope.row.logo_url" style="width:120px;height:90px;" fit="cover"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="建筑类型" prop="building.name" width="120"></el-table-column>
      <el-table-column label="标题" prop="name" width="300"></el-table-column>
      <el-table-column label="内部名称" prop="private_name" width="160"></el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag
            :type="
              scope.row.status == 0
                ? 'info'
                : scope.row.status == 5
                ? ''
                : scope.row.status == 10
                ? 'success'
                : scope.row.status == 15
                ? 'danger'
                : ''
            "
          >{{ scope.row.status_text }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="门店" prop="shop.name" width="180"></el-table-column>
      <el-table-column label="价格" width="150">
        <template #default="scope">
          <div>平日：￥{{scope.row.decimal_normal_price}}</div>
          <div>周末日：￥{{scope.row.decimal_weekend_price}}</div>
          <div>节日日：￥{{scope.row.decimal_holiday_price}}</div>
        </template>
      </el-table-column>

      <el-table-column label="房间" width="200">
        <template #default="scope">
          <el-tag
            v-for="item in scope.row.stores"
            :key="item.id"
            style="margin:0 10px 10px 0;"
          >{{item.house_no}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="户型" width="180">
        <template #default="scope">
          <div>
            {{ scope.row.bedroom }}房{{ scope.row.hall }}厅{{
            scope.row.bathroom
            }}卫{{ scope.row.kitchen }}厨{{ scope.row.balcony }}阳台
          </div>
        </template>
      </el-table-column>
      <el-table-column label="地址" prop="address" width="300"></el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            @click="onEdit(scope.row)"
            v-if="is_show_dtl"
          >修改资料</el-button>
          <el-button
            link
            type="primary"
            @click="onToggle(scope.row)"
            v-if="is_can_status_toggle && scope.row.status != 0"
          >上/下架</el-button>
          <el-button
            type="success"
            size="small"
            v-if="is_can_copy"
            @click="onCopy(scope.row)"
            style="margin-top: 10px"
          >复制</el-button>
          <el-popconfirm
            v-if="is_can_del"
            title="删除后将无法恢复，您定要删除？"
            @confirm.stop="onDelete(scope.row)"
            style="margin-top: 10px"
          >
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-house :item="current" :area_tree="area_tree" :buildings="buildings" @close="editClose"></edit-house>
</template>

<script>
import common_api from "@/http/common_api";
import EditHouse from "./edit_house.vue";
import BatchShop from "./batch_set_shop.vue";
import shop_api from "@/http/shop_api";
import BatchSetBasePrice from "./batch_set_base_price.vue";

export default {
  components: {
    EditHouse,
    BatchShop,
    BatchSetBasePrice,
  },
  data() {
    return {
      loading: false,
      //房源类型
      buildings: [],
      //城市区域
      area_tree: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //编辑使用
      current: null,
      //common
      common: {
        buildings: [],
        area_tree: [],
      },
      //
      enums: {},
      //门店
      shop_list: [],
      //查看修改
      is_show_dtl: false,
      is_can_status_toggle: false,
      is_can_del: false,
      is_can_copy: false,
    };
  },
  provide() {
    return {
      common: this.common,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    //房源类型
    common_api.get_building_tree().then((res) => {
      if (res.code == 0) {
        this.common.buildings = res.data;
      }
    });
    //区域
    common_api.get_area_tree().then((res) => {
      if (res.code == 0) {
        this.area_tree = res.data;
        this.common.area_tree = this.area_tree;
      }
    });
    //门店
    shop_api.get_shops().then((res) => {
      if (res.code == 0) {
        this.shop_list = res.data;
      }
    });
    this.loadData();
    //是否可以查看详情
    this.is_show_dtl = this.$power("seller/v1/house/dtl");
    //是否可以上下架切换
    this.is_can_status_toggle = this.$power("seller/v1/house/toggle");
    //是否可以删除
    this.is_can_del = this.$power("seller/v1/house/del");
    this.is_can_copy = this.$power("seller/v1/house/copy");
  },
  methods: {
    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/house", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 区域选择更改
     */
    areaChange(values) {
      if (values) {
        this.query.area_id = values[values.length - 1];
      } else {
        this.query.area_id = null;
      }
    },
    /**
     *
     */
    buildingChange(values) {
      if (values) {
        this.query.building_id = values[values.length - 1];
      } else {
        this.query.building_id = null;
      }
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 编辑框关闭
     */
    editClose(update) {
      this.current = null;
      if (update) {
        this.loadData();
      }
    },
    /**
     * 删除
     */
    onDelete(item) {
      this.$http.get("seller/v1/house/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
      });
    },
    /**
     * 上下架
     */
    onToggle(item) {
      this.$http.get("seller/v1/house/toggle?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
      });
    },
    /**
     * 复制房源资料
     */
    onCopy(item) {
      console.log(item);
      this.$confirm(`您确定要复制房源 ${item.private_name}？`, "房源复制", {
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      })
        .then(() => {
          // this.loading = true;
          var el_load = this.$loading({
            fullscreen: true,
            text: "复制中...",
          });
          this.$http
            .post("seller/v1/house/copy", {
              id: item.id,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
              el_load.close();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 30px;
}
.grid .item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.grid .item .info {
  flex-grow: 1;
  padding: 10px;
  /* height: 140px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.grid .item .name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.grid .item .title {
  font-size: 12px;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.grid .item .status {
  position: absolute;
  left: 10px;
  top: 10px;
}

.grid .el-card .el-card__body {
  height: 100%;
}

.grid .item .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid .item .bottom .time {
  font-size: 14px;
  color: #999;
}

.grid .item .bottom .button {
  padding: 0;
  min-height: auto;
}

.grid .item .el-image {
  width: 100%;
  height: 200px;
}
.grid .item .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 14px;
}
.grid .item .dot {
  animation: dot 2s infinite steps(3, start);
  overflow: hidden;
}
</style>